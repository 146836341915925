@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap');

*, html, .sui-facet__title {
font-family: 'IBM Plex Sans Arabic', sans-serif !important;
}


.sui-facet__title {
font-family: 'IBM Plex Sans Arabic', sans-serif !important;
letter-spacing: 0px;

}
.App {
  font-family: sans-serif;
}

.main-result {
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
  background: #f9f9f9 !important;
  margin-bottom: 5px;
  border-radius: 3px;

}
.flex {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #FFFFFF;
    border-radius: 3px;

/**background-image: url("https://rasaif-bb6e9f.ingress-comporellon.ewp.live/wp-content/uploads/2021/05/back.png");
background-position: center center;
background-repeat: repeat; **/
}

@media (max-width: 650px) {
  .flex-right, .flex-left {
    width: 100%;
  }
  .flex {
   flex-direction: column;
   }
   .sui-layout-header__inner {
width: 100% !important;
}
}

.flex-left {
direction: ltr !important;
text-align: justify;
text-align-last: left;
padding: 1%;
flex: 50%;
border-right: 1px solid #e1e1e1;
}

.flex-right {
direction: rtl !important;
text-align: justify;
text-align-last: right;
padding: 1%;
flex: 50%;
}
.flex:nth-child(even) {
}

.sui-paging .rc-pagination-item a  {
color: #48484a;
font-weight: bold;
}

.rc-pagination-item-active  {
background: #48484a !important;
color: white !important;
}

.rc-pagination-item-active a {
color: white !important;
}


.sui-layout-sidebar {
/**display: none;**/
padding: 32px 10px 0px 10px;
}

.sui-layout-body__inner, .sui-layout-main {
width: 100% !important;
margin: auto;
padding: 2%;
background: white;
direction: rtl !important;
}

.sui-layout, .sui-layout-body {
  background: white;

}
.sui-layout-header {
padding-left: 9%;
padding-right: 9%;
padding-top: 5px;
}
.sui-layout-header__inner {
width: 80%;
margin: auto;
}
em {
color: white;
font-weight: normal;
text-decoration: none;
background: #a68537;
font-style: normal;
padding-right: 2px;
padding-left: 2px;
border-radius: 3px;
}

.sui-search-box {


}
.sui-search-box__text-input {
  border: 2px solid #a68537 !important;
border-radius: 5px;
color: #48484a;
font-weight: 500;
padding-top: 10px;
padding-bottom: 10px;
direction: rtl;
}

.sui-search-box__text-input:focus {
border: 6px;
border-radius: 5px;
color: #48484a;
font-weight: 500;
}
.sui-search-box__wrapper{
border-radius: 5px;
display: flex;
flex-direction: row-reverse;

}

.sui-search-box__submit {
  margin-right: 10px;
color: white;
font-weight: 500;
border-radius: 5px;
background: #a68537;
padding-top: 3px;
padding-bottom: 3px;
padding-right: 10px;
padding-left: 10px;

}

.sui-search-box__submit:hover {
color: #a68537;
font-weight: 500;
border-radius: 5px;
background: white;
border: 1px solid #a68537;
}

.logoimg {
max-width: 250px;
float: right;
}
.header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
}

.header-link {
  float: left;
  color: #a68537;
  font-weight: 500;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.title-field {
  direction: rtl;
  color: #a68537;
  font-size: x-small;
  background: #f9f9f9;
  border-bottom: 1px solid #e1e1e1;
  padding: 3px;
}

.sui-layout-main-header {

  margin-bottom: 15px;
}
.wrapper {
  border-collapse: collapse;

}

.sui-layout-sidebar-toggle {

color: #a68537;
border-color: #a68537;

}

.sui-paging-info, .sui-select__single-value, .sui-results-per-page {
  color: #48484a;

}

.sui-results-per-page {

  direction: ltr;
}


.sui-results-per-page__label {

  display: none;
}

.book, .author {
}
